//TO ADD ON SITEMAP
// Main Pages
export const HOME = '/';
export const HOME_PRO = '/pro';
export const PRODUCT_EXPLORER = '/pro/produit/explorateur-de-ventes';
export const PRODUCT_ANALYZER = '/pro/produit/analyseur-de-biens';
export const PRODUCT_WIDGET = '/pro/produit/module-estimation';
export const PRODUCT_SHOWCASE = '/pro/produit/vitrine';
export const PRODUCT_MARKET_ANALYSER = '/pro/produit/analyseur-de-marche';
export const PRODUCT_PROSPECTS = '/pro/produit/prospects-proximite';
export const PRODUCT_API = '/pro/produit/api';

export const DVF = '/dvf';
export const PATRIM = '/patrim';
export const CONTACT = '/contact';
export const CONTACT_BASE64 = 'L2NvbnRhY3Q=';
export const PRICING = '/tarifs';
export const PRICING_PRO = '/pro/tarifs';
export const WELCOME = '/bienvenue';
export const PAGE_NOT_FOUND = '/page-not-found';
export const REDIRECT = '/redirect';

//Agents directory
export const AGENCIES_AND_AGENTS_DIRECTORY_RESULTS_ADDRESS =
    '/agences-et-mandataires-immobiliers/:regionPath/:departmentPath/:cityPath/voie/:streetPath/:streetNumber';
export const AGENCIES_AND_AGENTS_DIRECTORY_RESULTS_STREET =
    '/agences-et-mandataires-immobiliers/:regionPath/:departmentPath/:cityPath/voie/:streetPath';
export const AGENCIES_AND_AGENTS_DIRECTORY_RESULTS_DISTRICT =
    '/agences-et-mandataires-immobiliers/:regionPath/:departmentPath/:cityPath/:districtPath';
export const AGENCIES_AND_AGENTS_DIRECTORY_RESULTS_CITY =
    '/agences-et-mandataires-immobiliers/:regionPath/:departmentPath/:cityPath';
export const AGENCIES_AND_AGENTS_DIRECTORY_RESULTS_DEPARTMENT =
    '/agences-et-mandataires-immobiliers/:regionPath/:departmentPath';
export const AGENCIES_AND_AGENTS_DIRECTORY_RESULTS_REGION =
    '/agences-et-mandataires-immobiliers/:regionPath';
export const AGENCIES_AND_AGENTS_DIRECTORY =
    '/agences-et-mandataires-immobiliers';

// Valuation form
export const VALUATION_ADDRESS = '/estimation/1';
export const VALUATION_PROPERTY = '/estimation/2';
export const VALUATION_NBROOMS = '/estimation/3';
export const VALUATION_SURFACE = '/estimation/4';
export const VALUATION_ISPRO = '/estimation/5';
export const VALUATION_CATEGORY = '/estimation/6';
export const VALUATION_PROJECT_STATUS = '/estimation/7';
export const VALUATION_LOADING = '/estimation/8';
export const VALUATION_RESULT = '/estimation/9';

// Explorer Pages
export const TRANSACTION_EXPLORER = '/explorateur/transaction';
export const PROPERTY_EXPLORER = '/explorateur/bien';
export const TRANSACTION_EXPLORER_SEARCH = '/explorateur/transaction/recherche';
export const PROPERTY_EXPLORER_SEARCH = '/explorateur/bien/recherche';
export const PROPERTY_EXPLORER_REPORT_ROOT = '/explorateur/bien/rapport/';

export const MARKET_ANALYSER_ROOT = '/analyseur/marche';
export const MARKET_ANALYSER = MARKET_ANALYSER_ROOT + '/:geoLevel?/:code?';

export const PROPERTY_EXPLORER_REPORT =
    PROPERTY_EXPLORER_REPORT_ROOT + ':reportId';
export const PROPERTY_EXPLORER_REPORT_ONLINE = '/rapport/:token';
export const VALUATION_WIDGET = '/module-estimation';
export const LEADS = '/prospects';
export const PROSPECTS = '/prospects-proximite';
export const SHOWCASE_CATEGORY = '/vitrine/creation/1';
export const SHOWCASE_IS_INDEPENDENT = '/vitrine/creation/2';
export const SHOWCASE_NETWORK = '/vitrine/creation/3';
export const SHOWCASE_NAME = '/vitrine/creation/4';
export const SHOWCASE_LOCATION = '/vitrine/creation/5';
export const SHOWCASE_COVERAGE = '/vitrine/creation/6';
export const SHOWCASE_SIRET = '/vitrine/creation/7';
export const SHOWCASE_REVIEW = '/vitrine/validation/:id';
export const SHOWCASE_PUBLIC = '/vitrine/:slug';
export const SHOWCASE = '/vitrine';

// Blog Pages
export const BLOG_POST = '/blog/:slug';
export const BLOG_HOME = '/blog';

export const BLOG_CADASTRE = '/cadastre-en-ligne';
export const APPRAISAL_TOOL = '/outil-estimation-immobiliere';
export const PROPERTY_VALUATION = '/estimation-bien-immobilier';

//NOT IN SITEMAP OR GENERATED AUTOMATICALLY

// Redirected Pages
export const SALES_TX = '/ventes-immobilieres';
export const MAP = '/map';
export const FEATURES = '/fonctionnalites';
// Auth Pages
export const SIGN_UP = '/inscription';
export const SIGN_UP_BASE64 = 'L2luc2NyaXB0aW9u';
export const LOGIN = '/connexion';
export const LOGIN_BASE64 = 'L2Nvbm5leGlvbg==';
export const PASSWORD_FORGET = '/oublie-mdp';
export const ACTION = '/action';

// Payment Pages
export const PAYMENT_CHECKOUT = '/paiement';

// Account Page
export const ACCOUNT = '/compte';
export const PROFILE = '/compte/profile';
export const SECURITY = '/compte/securite';
export const SUBSCRIPTION = '/compte/abonnement';
export const PARAMETERS = '/compte/parametres';
export const TEMPLATES = '/compte/modeles';

// Access Management Page
export const ACCESS_MANAGEMENT = '/parametres-acces';

// NPS Pages
export const NPS_THANKS = '/nps-thanks';
export const NPS_FEEDBACK = '/nps-feedback';

// Market explorer
export const MARKET_EXPLORER_SHORT_ADDRESS =
    '/marche-immobilier/voie/:streetPath/:streetNumber';
export const MARKET_EXPLORER_ADDRESS =
    '/marche-immobilier/:regionPath/:departmentPath/:cityPath/voie/:streetPath/:streetNumber';
export const MARKET_EXPLORER_STREET =
    '/marche-immobilier/:regionPath/:departmentPath/:cityPath/voie/:streetPath';
export const MARKET_EXPLORER_DISTRICT =
    '/marche-immobilier/:regionPath/:departmentPath/:cityPath/:districtPath';
export const MARKET_EXPLORER_CITY =
    '/marche-immobilier/:regionPath/:departmentPath/:cityPath';
export const MARKET_EXPLORER_DEPARTMENT =
    '/marche-immobilier/:regionPath/:departmentPath';
export const MARKET_EXPLORER_REGION = '/marche-immobilier/:regionPath';
export const MARKET_EXPLORER = '/marche-immobilier';

// Landing Pages
export const LANDING_INDEPENDANT_AGENT =
    '/pro/outil-immobilier-agents-mandataires';
export const LANDING_REAL_ESTATE_AGENT =
    '/pro/outil-immobilier-agents-immobiliers';
export const EMBED_DVF = '/integration-carte-dvf';

// DEPRECATED ROUTES
export const DEPRECATED_AGENT_DIRECTORY_COMING_SOON =
    '/professionnels-immobilier';
export const DEPRECATED_LANDING_INDEPENDANT_AGENT =
    '/outil-immobilier-agents-mandataires';
export const DEPRECATED_LANDING_REAL_ESTATE_AGENT =
    '/outil-immobilier-agents-immobiliers';
export const DEPRECATED_PRODUCT_EXPLORER = '/produit/explorateur-de-ventes';
export const DEPRECATED_PRODUCT_ANALYZER = '/produit/analyseur-de-biens';
export const DEPRECATED_PRODUCT_WIDGET = '/produit/module-estimation';

export const CGU = '/cgu';
export const CGU_BASE64 = 'L2NndQ==';
export const CGV = '/cgv';
export const CGV_BASE64 = 'L2Nndg==';
export const LEGAL_TERMS = '/mentions-legales';
export const LEGAL_TERMS_BASE64 = 'L21lbnRpb25zLWxlZ2FsZXM=';
export const SITEMAP = '/plan-du-site';
